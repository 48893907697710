import React from "react";

const StepThree = () => {
  return (
    <section>
      <div className="row">
        <div className="col-sm-4 mb-2">
          <h4>Monday *</h4>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="9.00"
              type="number"
              name="input1"
              id="input1"
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="6.00"
              type="number"
              name="input2"
              id="input2"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mb-2">
          <h4>Tuesday *</h4>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="9.00"
              type="number"
              name="input3"
              id="input3"
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="6.00"
              type="number"
              name="input4"
              id="input4"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mb-2">
          <h4>Wednesday *</h4>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="9.00"
              type="number"
              name="input5"
              id="input5"
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="6.00"
              type="number"
              name="input6"
              id="input6"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mb-2">
          <h4>Thrusday *</h4>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="9.00"
              type="number"
              name="input7"
              id="input7"
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="6.00"
              type="number"
              name="input8"
              id="input8"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mb-2">
          <h4>Friday *</h4>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="9.00"
              type="number"
              name="input9"
              id="input9"
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <div className="form-group">
            <input
              className="form-control"
              value="6.00"
              type="number"
              name="input10"
              id="input10"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepThree;
