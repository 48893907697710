export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons-outlined">dashboard</i>,
    ...(process.env.NODE_ENV == "development" && { to: "dashboard" }),
    ...(process.env.NODE_ENV === "development" && {
      content: [
        {
          title: "Dashboard Light",
          to: "dashboard",
        },
        {
          title: "Dashboard Dark",
          to: "dashboard-dark",
        },
        {
          title: "Banking",
          to: "banking",
        },
        {
          title: "Ticketing",
          to: "ticketing",
        },
        {
          title: "Crypto",
          to: "crypto",
        },
        {
          title: "Invoice",
          to: "invoice",
        },
        {
          title: "Contact",
          to: "contact",
        },
        {
          title: "Kanban",
          to: "kanban",
        },
      ],
    }),
  },
  {
    title: "Leads",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-050-info">app_registration </i>,
    to: "leads",
  },
  {
    title: "Enquiry",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">assessment</i>,
    to: "enquiry",
  },
  {
    title: "Follow ups",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-list" aria-hidden="true"></i>,
    to: "follow-ups",
  },
  {
    title: "Tickets",
    classsChange: "mm-collapse",
    iconStyle: <i className="merial-icons">article</i>,
    to: "tickets",
  },
  {
    title: "Works",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">extension </i>,
    // to: "follow",
    content: [
      {
        title: "Task",
        to: "/task",
      },
      {
        title: "Timesheet",
        to: "/timesheet",
      },
    ],
  },
  {
    title: "Finance",
    classsChange: "mm-collapse",
    iconStyle: <i className="mdi-bank" />,
    // to: "follow",
    content: [
      {
        title: "Quotation",
        to: "quotation",
      },
      {
        title: "Invoice",
        to: "invoice",
      },
      {
        title: "Credit Invoice",
        to: "credit-Invoice",
      },
      {
        title: "Supplier Payment",
        to: "supplier-payments",
      },
    ],
  },
  {
    title: "Mails",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">folders</i>,
    to: "email-inbox",
  },
  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">settings</i>,
    // to: "settings",
    content: [
      {
        title: "Admin Settings",
        to: "settings",
      },
      {
        title: "Company Settings",
        to: "company-settings",
      },
      {
        title: "Fields",
        to: "fields",
      },
      {
        title: "Currency Settings",
        to: "currency-settings",
      },
      {
        title: "Package Terms",
        to: "package-terms",
      },
      {
        title: "User Management",
        //to: './',
        hasMenu: true,
        content: [
          {
            title: "User",
            to: "user",
          },
          {
            title: "User Role",
            to: "user-role",
          },
        ],
      },
    ],
  },
  ...(process.env.NODE_ENV === "development"
    ? [
        {
          title: "File Manager",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">folder</i>,
          content: [
            {
              title: "File Manager",
              to: "file-manager",
            },
            {
              title: "User",
              to: "user",
            },
            {
              title: "Calendar",
              to: "calendar",
            },
            {
              title: "To Do List",
              to: "to-do-list",
            },
            {
              title: "Chat",
              to: "chat",
            },
            {
              title: "Activity",
              to: "activity",
            },
          ],
        },
        {
          title: "Course",
          classsChange: "mm-collapse",
          update: "New",
          iconStyle: <i className="mdi-bank" />,
          content: [
            {
              title: "Course List",
              to: "/course-listing",
            },
            {
              title: "Course Details",
              to: "/course-details",
            },
          ],
        },
        //CMS
        {
          title: "CMS",
          classsChange: "mm-collapse",
          update: "New",
          iconStyle: <i className="material-icons">settings</i>,
          content: [
            {
              title: "Content",
              to: "/content",
            },
            {
              title: "Menu",
              to: "/menu",
            },
            {
              title: "Email Template",
              to: "/email-template",
            },
            {
              title: "Blog",
              to: "/blog",
            },
          ],
        },
        //Apps
        {
          title: "Apps",
          classsChange: "mm-collapse",
          iconStyle: <i className="flaticon-050-info">app_registration </i>,
          content: [
            {
              title: "Profile",
              to: "app-profile",
            },
            {
              title: "Edit Profile",
              to: "edit-profile",
            },
            {
              title: "Post Details",
              to: "post-details",
            },
            {
              title: "Email",
              //to: './',
              hasMenu: true,
              content: [
                {
                  title: "Compose",
                  to: "email-compose",
                },
                {
                  title: "Index",
                  to: "email-inbox",
                },
                {
                  title: "Read",
                  to: "email-read",
                },
              ],
            },
            {
              title: "Calendar",
              to: "app-calender",
            },
            {
              title: "Shop",
              //to: './',
              hasMenu: true,
              content: [
                {
                  title: "Product Grid",
                  to: "ecom-product-grid",
                },
                {
                  title: "Product List",
                  to: "ecom-product-list",
                },
                {
                  title: "Product Details",
                  to: "ecom-product-detail",
                },
                {
                  title: "Order",
                  to: "ecom-product-order",
                },
                {
                  title: "Checkout",
                  to: "ecom-checkout",
                },
                {
                  title: "Invoice",
                  to: "ecom-invoice",
                },
                {
                  title: "Customers",
                  to: "ecom-customers",
                },
              ],
            },
          ],
        },
        //Charts
        {
          title: "Charts",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">assessment</i>,
          content: [
            {
              title: "RechartJs",
              to: "chart-rechart",
            },
            {
              title: "Chartjs",
              to: "chart-chartjs",
            },
            {
              title: "Sparkline",
              to: "chart-sparkline",
            },
            {
              title: "Apexchart",
              to: "chart-apexchart",
            },
          ],
        },
        //Boosttrap
        {
          title: "Bootstrap",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">favorite</i>,
          content: [
            {
              title: "Accordion",
              to: "ui-accordion",
            },
            {
              title: "Alert",
              to: "ui-alert",
            },
            {
              title: "Badge",
              to: "ui-badge",
            },
            {
              title: "Button",
              to: "ui-button",
            },
            {
              title: "Modal",
              to: "ui-modal",
            },
            {
              title: "Button Group",
              to: "ui-button-group",
            },
            {
              title: "List Group",
              to: "ui-list-group",
            },
            {
              title: "Cards",
              to: "ui-card",
            },
            {
              title: "Carousel",
              to: "ui-carousel",
            },
            {
              title: "Dropdown",
              to: "ui-dropdown",
            },
            {
              title: "Popover",
              to: "ui-popover",
            },
            {
              title: "Progressbar",
              to: "ui-progressbar",
            },
            {
              title: "Tab",
              to: "ui-tab",
            },
            {
              title: "Typography",
              to: "ui-typography",
            },
            {
              title: "Pagination",
              to: "ui-pagination",
            },
            {
              title: "Grid",
              to: "ui-grid",
            },
          ],
        },
        //plugins
        {
          title: "Plugins",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">extension </i>,
          content: [
            {
              title: "Select 2",
              to: "uc-select2",
            },
            // {
            //     title:'Noui Slider',
            //     to: 'uc-noui-slider',
            // },
            {
              title: "Sweet Alert",
              to: "uc-sweetalert",
            },
            {
              title: "Toastr",
              to: "uc-toastr",
            },
            {
              title: "Jqv Map",
              to: "map-jqvmap",
            },
            {
              title: "Light Gallery",
              to: "uc-lightgallery",
            },
          ],
        },
        //Widget
        {
          title: "Widget",
          //classsChange: 'mm-collapse',
          iconStyle: <i className="material-icons">widgets</i>,
          to: "widget",
        },
        //Forms
        {
          title: "Forms",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">insert_drive_file</i>,
          content: [
            {
              title: "Form Elements",
              to: "form-element",
            },
            {
              title: "Wizard",
              to: "form-wizard",
            },
            {
              title: "CkEditor",
              to: "form-ckeditor",
            },
            {
              title: "Pickers",
              to: "form-pickers",
            },
            {
              title: "Form Validate",
              to: "form-validation",
            },
          ],
        },
        //Table
        {
          title: "Table",
          classsChange: "mm-collapse",
          iconStyle: <i className="material-icons">table_chart</i>,
          content: [
            {
              title: "Table Filtering",
              to: "table-filtering",
            },
            {
              title: "Table Sorting",
              to: "table-sorting",
            },
            {
              title: "Bootstrap",
              to: "table-bootstrap-basic",
            },
          ],
        },
        //Pages
        {
          title: "Pages",
          classsChange: "mm-collapse",
          iconStyle: <i className="merial-icons">article</i>,
          content: [
            {
              title: "Error",
              hasMenu: true,
              content: [
                {
                  title: "Error 400",
                  to: "page-error-400",
                },
                {
                  title: "Error 403",
                  to: "page-error-403",
                },
                {
                  title: "Error 404",
                  to: "page-error-404",
                },
                {
                  title: "Error 500",
                  to: "page-error-500",
                },
                {
                  title: "Error 503",
                  to: "page-error-503",
                },
              ],
            },
            {
              title: "Lock Screen",
              to: "page-lock-screen",
            },
          ],
        },
      ]
    : []),
];
