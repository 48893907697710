import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import CustomModal from '../../layouts/CustomModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const ViewerModal = ({ file, showModal, handleClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
    {/* <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered
      dialogClassName="custom-pdf-viewer">
      <Modal.Header closeButton>
        <Modal.Title>PDF Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'rgba(0,0,0,0.1)', display: 'flex', justifyContent: 'center' }}>
        <Document file={file} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber}  />
        </Document>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: 'transparent' }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => window.open(file, '_blank')} download>
          Download
        </Button>
      </Modal.Footer>
    </Modal> */}
    <CustomModal showModal={showModal} title={"PDF Viewer"} handleModalClose={handleClose}>
      <div className="row">
        <div className="col-12">
          <div className='d-flex align-items-center justify-content-center py-3 mb-3' style={{backgroundColor:'rgba(0,0,0,0.1)'}}>
            <Document file={file} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} height={800} />
            </Document>
          </div>
          <button className="btn btn-light" onClick={handleClose}>
            Cancel
          </button>
          <button className={`btn btn-primary ms-3`} onClick={() => window.open(file, '_blank')}>
            Download
          </button>
        </div>
      </div>
    </CustomModal>
    </>
  );
};
