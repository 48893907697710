export const URLS = {
  LOGIN_URL: "/user/login",
  REGISTER_URL: "/user/register",
  USER_GET_URL: "/user/list",
  USER_GET_BY_ID_URL: "/user/show",
  USER_UPDATE_URL: '/user/update',
  DESTINATION_URL: "/destinations",
  SUB_DESTINATION_URL: "/sub-destinations",
  PROPERTY_CATEGORY_URL: "/categories",
  PROPERTY_TYPE_URL: "/property-types",
  ROOM_TYPE_URL: "/room-types",
  MARKET_TYPE_URL: "/market-types",
  ROOM_AMENITIES_URL: "/room-amenities",
  HOTEL_AMENITIES_URL: "/hotel-amenities",
  MEAL_PLAN_URL: "/meal-plans",
  PERMISSION_URL: "/permissions",
  USER_ROLE_URL: "/roles",
  HOTEL_URL: '/hotels',
  HOTEL_UPDATE_URL: '/hotel-update',
  LANGUAGE_URL:'settings/languages',
  AGENT_URL: '/agents',
  COUNTRY_URL:'/settings/countries',
  LEAD_SOURCE_URL:'/lead-sources',
  PRIORITY_URL:'/priorities',
  REQUIREMENT_URL:'/requirements',
  CHANGE_PASSWORD_URL:'/user/change-password',
  ACTIVITY_URL:'/activities',
  ACTIVITY_UPDATE_URL:'/activity-status-update',
  TRANSFER_URL:'/transfers',
  TRANSFER_UPDATE_URL:'/transfer-update',
  TRANSFER_PATCH_URL:'/transfer-status-update',
  ENQUIRY_URL:'enquiries',
  ITINERARY_URL:'itineraries',
  PRINT_ITINERARY_URL:'itinerary/print/',
  ITINERARY_UPDATE_URL:'itinerary-update',
  CUSTOMER_URL:'customers-search-by-mobile',
};
